<template>
  <div>
    <!-- Header -->

    <header id="main-header">
      <div class="">
        <div class="row">
          <!-- <div class="text-success"><b>Lite: {{this.$store.state.currentUser.membershipLevel}}</b></div><br> -->

          <div class="col-sm-12">
            <nav class="navbar navbar-expand-lg navbar-light p-0">
              <a
                href="#"
                class="navbar-toggler c-toggler"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <div
                  class="navbar-toggler-icon"
                  data-toggle="collapse"
                  @click="sidebarOpened = true"
                >
                  <span class="navbar-menu-icon navbar-menu-icon--top"></span>
                  <span
                    class="navbar-menu-icon navbar-menu-icon--middle"
                  ></span>
                  <span
                    class="navbar-menu-icon navbar-menu-icon--bottom"
                  ></span>
                </div>
              </a>
              <a
                class="navbar-brand"
                v-on:click="go('/')"
                style="cursor: pointer"
              >
                <img
                  v-if="
                    !this.$store.state.currentUser.membershipLevel == 'lite'
                  "
                  class="img-fluid logo"
                  src="/images/logo.png"
                  alt="streamit"
                />
                <img
                  v-if="this.$store.state.currentUser.membershipLevel == 'lite'"
                  class="img-fluid logo"
                  src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/litebg.png?alt=media&token=2d7abf13-0798-4959-ad3a-f7075f426cb3"
                  alt="streamit"
                />
              </a>
              <div
                class="collapse navbar-collapse"
                id="navbarSupportedContent"
                ref="sidebarMenu"
              >
                <div class="menu-main-menu-container">
                  <span id="close-btn" @click="closeSidebar">X</span>
                  <ul id="top-menu" class="navbar-nav ml-auto">
                    <li class="menu-item">
                      <router-link :to="'/section/marketing'"
                        >Marketing</router-link
                      >
                    </li>
                    <li class="menu-item">
                      <router-link :to="'/section/sales'">Sales</router-link>
                    </li>
                    <li class="menu-item">
                      <router-link
                        :to="'/section/multi-op'"
                        v-if="
                          !this.$store.state.currentUser.membershipLevel ==
                            'lite'
                        "
                        >Multi-Op</router-link
                      >
                    </li>
                    <li class="menu-item">
                      <router-link :to="'/section/gear'">Gear</router-link>
                    </li>
                    <li class="menu-item">
                      <router-link :to="'/section/performance'"
                        >Performance</router-link
                      >
                    </li>

                    <!-- <li class="menu-item">
                      <router-link :to="'/section/upsells'"
                        >Upsells</router-link
                      >
                    </li> -->

                    <!-- <li class="menu-item">
                      <router-link :to="'/section/networking'"
                        >Networking</router-link
                      >
                    </li> -->
                    <li class="menu-item">
                      <router-link
                        :to="'/section/webinars'"
                        v-if="
                          !this.$store.state.currentUser.membershipLevel !==
                            'lite'
                        "
                        >Webinars</router-link
                      >
                    </li>
                    <li
                      class="menu-item"
                      v-if="
                        this.$store.state.currentUser.membershipLevel !== 'lite'
                      "
                    >
                      <router-link :to="'/section/officehours'"
                        >Office Hours</router-link
                      >
                    </li>
                    <li
                      class="menu-item"
                      v-if="
                        this.$store.state.currentUser.membershipLevel !== 'lite'
                      "
                    >
                      <router-link :to="'/documents'">Docs</router-link>
                    </li>
                    <li
                      class="menu-item"
                      v-if="
                        this.$store.state.currentUser.membershipLevel !== 'lite'
                      "
                    >
                      <router-link :to="'/educators'">Educators</router-link>
                    </li>
                    <li
                      class="menu-item"
                      v-if="
                        this.$store.state.currentUser.membershipLevel !== 'lite'
                      "
                    >
                      <router-link :to="'/rest'">The Rest</router-link>
                    </li>
                    <!-- <li class="menu-item">
                      <a href="https://www.thedjsvault.com/mentor" target="_blank">Mentor Program</a>
                    </li> -->
                    <li
                      class="menu-item"
                      v-if="
                        this.$store.state.currentUser.membershipLevel ==
                          'annual'
                      "
                    >
                      <router-link :to="'/section/annual'">Annual</router-link>
                    </li>

                    <li
                      class="menu-item"
                      v-if="
                        mayhem.includes(this.$store.state.currentUser.email)
                      "
                    >
                      <router-link :to="'/section/mayhem'">Mayhem</router-link>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="navbar-right menu-right">
                <ul class="d-flex align-items-center list-inline m-0">
                  <li class="nav-item nav-icon">
                    <!-- <a href="#" class="search-toggle device-search"> -->

                    <router-link :to="'/favorites'">
                      <i class="fa fa-heart  mr-3 text-danger"></i>
                    </router-link>
                    <router-link
                      :to="'/search'"
                      v-if="
                        this.$store.state.currentUser.membershipLevel !== 'lite'
                      "
                    >
                      <i class="ri-search-line"></i>
                    </router-link>
                    <!-- </a> -->
                    <!-- <div class="search-box iq-search-bar d-search">
                      <form
                        action="#"
                        class="searchbox"
                        @submit.prevent="onSubmit"
                      >
                        <div
                          class="form-group position-relative"
                          style="margin-bottom: 0px"
                        >
                          <input
                            type="text"
                            v-model="query"
                            class="text search-input font-size-12"
                            placeholder="type here to search..."
                          />
                          <i class="search-link ri-search-line"></i>
                        </div>
                        <div class="search-suggestions">
                          <div
                            class="row search-item"
                            v-for="(suggestion, index) in suggestions"
                            :key="index"
                            @click="onSuggestionClick(suggestion)"
                          >
                            <div class="col-md-12">

                              <strong>{{ suggestion.Title }}</strong>
                             <img class="float-right"
                                :src="suggestion.Thumbnail"
                                style="width: 70px"
                              />
                            </div>
                           
                          </div>
                        </div>
                      </form>
                    </div> -->
                  </li>
                  <li
                    class="menu-item d-none d-sm-block d-sm-none d-md-block"
                    style="cursor: pointer"
                  >
                    <a @click="logout()">Logout</a>
                  </li>

                  <li class="nav-item nav-icon">
                    <div class="iq-sub-dropdown iq-user-dropdown">
                      <div class="iq-card shadow-none m-0">
                        <div class="iq-card-body p-0 pl-3 pr-3">
                          <a class="iq-sub-card setting-dropdown">
                            <div class="media align-items-center">
                              <div class="right-icon">
                                <i class="ri-file-user-line text-primary"></i>
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0">
                                  {{ $store.state.currentUser.displayName }}
                                </h6>
                              </div>
                            </div>
                          </a>
                          <a class="iq-sub-card setting-dropdown">
                            <div class="media align-items-center">
                              <div class="right-icon">
                                <i class="ri-settings-4-line text-primary"></i>
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0">
                                  {{ $store.state.currentUser.uid }}
                                </h6>
                              </div>
                            </div>
                          </a>
                          <a class="iq-sub-card setting-dropdown">
                            <div class="media align-items-center">
                              <div class="right-icon">
                                <i class="ri-settings-4-line text-primary"></i>
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0">Admin</h6>
                              </div>
                            </div>
                          </a>
                          <a class="iq-sub-card setting-dropdown">
                            <div class="media align-items-center">
                              <div class="right-icon">
                                <i
                                  class="ri-logout-circle-line text-primary"
                                ></i>
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0">Logout</h6>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>

            <div class="nav-overlay"></div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header End -->
  </div>
</template>

<script>
import firebase from "firebase/app";

import { db } from "@/db.js";
export default {
  data: function() {
    return {
      posts: [],
      sidebarOpened: false,
      query: "",
      suggestions: [],
      sections: [],
      mayhem: [
        "dominick.pirone@gmail.com",
        "matt@mountaineventservices.com",
        "kraig@jukeboxhits.com",
        "info@mastersofmusicdj.com",
        "jason@spencerweddings.com",
        "GERRY@ULTRAEVENTGROUP.COM",
        "john@perfectdj.net",
        "randy@bunndjcompany.com",
        "craig@elitesoundskc.com",
        "djstan@stansmobiledj.com",
        "djktpg@gmail.com",
        "djrick@atrdj.com",
        "larry@aandadj.com",
        "mcocco@thecoccogroup.com",
        "1cooldj@gmail.com",
        "djmac6067@gmail.com",
        "mike@newcitydj.com",
        "tony@mndiscjockeys.com",
        "joe@exceptional-entertainment.com",
        "ben@highergroundent.com",
        "djgenew@morethanjustdancedj.com",
        "Djchaosproductions@gmail.com",
        "uptownsounddj@hotmail.com",
        "george.koury@gmail.com",
        "Info@thedjmajesty.com",
        "dj-g52@outlook.com",
        "Timothysmith77@aol.com",
        "jared@purevisiondj.com",
        "robert@sonicsculptor.com",
        "FourStarDJ@hotmail.com",
        "Spindiesel@mac.com",
        "Jerry@djjerryandsons.com",
        "dj@shavanosoundz.com",
        "info@sonicdjs.com",
        "sam@soireedjs.com",
      ],
    };
  },
  mounted() {
    //redirect if users going to firebase app

    if (window.location.href.includes("thedjsvault-307019")) {
      window.location.replace("https://app.thedjsvault.com");
    }
    // this.login();
    this.log();

    this.$bind("posts", db.collection("posts")).then(function() {});
    this.$bind("sections", db.collection("sections")).then(function() {});
    window.addEventListener("click", this.sidebarStateHandler);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.sidebarStateHandler);
  },
  watch: {
    query: {
      handler: "queryChangeHandler",
    },
  },
  methods: {
    log() {
      db.collection("log").add({
        name: this.$store.state.currentUser.displayName,
        email: this.$store.state.currentUser.email,
        page: window.location.href,
      });
    },
    go(path) {
      this.$router.push({ path: path }).catch(() => {});
    },
    sidebarStateHandler() {
      if (this.sidebarOpened) {
        this.closeSidebar();
      }
    },
    closeSidebar() {
      this.$refs.sidebarMenu.classList.remove("show");
    },
    async queryChangeHandler() {
      this.suggestions = [];
      if (this.query == "") return;

      for (let post of this.posts) {
        const postTitle = post.Title.toString().toLowerCase();

        if (postTitle.includes(this.query.toLowerCase()))
          this.suggestions.push(post);

        if (this.suggestions.length == 20) break;
      }
    },
    onSuggestionClick(post) {
      this.$router.push(`/post/${post.id}`);
    },
    onSubmit() {
      this.$router.push(`/search?query=${this.query}`);
    },
    logout() {
      const _this = this;
      firebase
        .auth()
        .signOut()
        .then(() => {
          _this.$store.commit({
            type: "logout",
          });

          _this.$router.push("/login").catch((err) => {});
        })
        .catch((error) => {
          // An error happened.
          alert("error:", error);
        });
    },
  },
};
</script>

<style scoped>
.search-suggestions {
  display: flex;
  flex-direction: column;
}

.search-item {
  border-bottom: 1px solid grey;
  padding: 8px;
  margin: 0;
  text-align: left;

  font-size: 12px;
  background-color: black;
}

.search-item:hover {
  background-color: black;
  cursor: pointer;
}

#close-btn {
  position: absolute;
  top: 0px;
  left: 25px;
  padding: 5px;
  cursor: pointer;
}
/* large screen hide X btn */
@media screen and (min-width: 992px) {
  #close-btn {
    display: none;
  }
}

.searchbox {
  border: 1px #fff solid;
}
</style>
