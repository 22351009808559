<template>
  <div v-if="events && events.length">

    <h4
      class="main-title"
      style="margin-top: 4px"
    >
      Upcoming Events
    </h4>

    <div
      class="row mt-3 event-card"
      v-for="(event, i) in events"
      :key="i"
    >
      <div class="col-md-2">
        <div class="image">
          <img
            :src="event.image"
            class="img img-responsive full-width"
          />
        </div>

        <!-- <img
          :src="event.image"
          class="img-fluid rounded mt-2 mb-2"
        /> -->
        <div class="event-date text-center"><b>{{getDay(event.date)}}</b><br>{{getMonth(event.date)}}</div>
      </div>
      <div class="col-md-10">
        <div class="text-light mt-4">
          <h5>{{ event.title }}</h5>
        </div>
        {{ getTime(event.date) }}

        <br><br>
        <div class="text-muted">{{event.description}}</div>

        <a
          :href="event.link"
          v-if="event.link"
          target="_blank"
        >
          <div
            class="text-center rounded pointer mt-4"
            style="background-color: red; width: 200px;height:34px; padding-top: 4px; position: absolute; right: 6px; bottom: 6px "
          ><i class="fa fa-facebook mr-3"></i>View on Facebook</div>
        </a>
        <p></p>
      </div>

    </div>

  </div>
</template>

<script>
import { db } from "@/db.js";
import moment from "moment";
export default {
  data () {
    return {
      events: [],
    };
  },
  mounted () {

    const d = new Date();
    this.$bind("events", db.collection("events2").where('active', '==', true).orderBy("date").startAt(d).limit(4)).then(function () { });
    console.log('events', this.events)
  },
  methods: {

    getMonth (d) {
      console.log("getMonth", d)

      // let newD = d.toDate().toDateString();
      return moment(d).format("MMM");

    },
    getDay (d) {

      console.log("getDay", d)

      // let newD = d.toDate().toDateString();

      return moment(d).format("D");

    },
    getTime (d) {

      console.log("getTime", d)
      // let newD = d.toDate();
      return moment(d).format("MMMM Do YYYY, h:mm a");

    },
  }
};
</script>

<style>
.event-date {
  position: absolute;
  top: 0px;
  left: 15px;
  width: 40px;
  height: 60px;

  padding: 5px;
  background-color: red;
  color: white;

  font-family: "Segoe UI", Tahoma, Verdana, sans-serif;
}

.event-card {
  border-bottom: 2px rgb(108, 108, 108) solid;
}

.image {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
}
.image img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>