import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: {
      displayName: "",
      loggedIn: false,
      image: "",
      email: "",
      uid: "",
      active: false,
      memberships: null,
      mentorProgram: false,
      annual: false,
      membershipLevel: "", //New: used for final membership. lite - standard - annual - DON'T REMOVE. USED IN FUNCTIONS
    },
    baseUrlWF: "https://thedjsvault.glenn-frank.com/version-test/api/1.1/wf/",
    baseUrlObj: "https://thedjsvault.glenn-frank.com/version-test/api/1.1/obj/",
  },
  mutations: {
    login(state, user) {
      //  state.currentUser = user;
      state.currentUser.loggedIn = true;
      state.currentUser.displayName = user.data.displayName;
      state.currentUser.image = user.data.photoURL;
      state.currentUser.email = user.data.email;
      state.currentUser.uid = user.data.uid;
      state.currentUser.active = user.data.active;

      console.log("Store: User Logged In", user);
    },
    logout(state) {
      //state.currentUser = null;
      state.currentUser.loggedIn = false;
      state.currentUser.displayName = null;
      state.currentUser.image = null;
      state.currentUser.email = null;
      state.currentUser.uid = null;
      console.log("Store: User Logged Out");
    },
    mentorProgram(state, data) {
      state.currentUser.mentorProgram = data.data;
      console.log("MENTOR PROGRAM MUTATION", data);
    },
    lite(state, data) {
      state.currentUser.lite = data.data;
      console.log("Lite Version", data);
    },
    memberships(state, data) {
      state.currentUser.memberships = data.data;
      console.log("DATA DATA", data.data);

      //we have the list of memberships. You can have one of 3 memberships. We store this final membership in membershipLevel
      // 1. lite
      // 2. standard
      // 3. annual

    
    },

    membershipLevel(state, data) {
      state.currentUser.membershipLevel = data.data;


      //we have the list of memberships. You can have one of 3 memberships. We store this final membership in membershipLevel
      // 1. lite
      // 2. standard
      // 3. annual

      console.log("STATE: membershipLevel", state.currentUser.membershipLevel);
    },
  },
  actions: {},
  modules: {},
});
