<template>
  <div>

    <!-- <div class="text-muted" v-if="this.number">Step {{this.number}} </div> -->
    <!-- <router-link :to="{ name: 'Post', params: { postId: post.id } }"> -->
    <div
      class="mr-2 post-card rounded mt-2"
      style=""
    >
      <div class="overlay">
        <div
          class="overlay-actions ml-1"
          v-if="post"
        >

        </div>
      </div>
      <img
        v-if="post.Thumbnail !== ''"
        v-bind:src="post.Thumbnail"
        class="img-fluid  rounded pointer hidden-sm"
        alt=""
        style="min-width: 100%"
        v-on:click="go(post.id)"
      />

      <div
        style="position:relative; text=align:center"
        v-if="post.Thumbnail == ''"
      >
        <img
          src="/images/vaultbgDoc.jpg"
          style="width:100%;"
          v-on:click="go(post.id)"
          class="pointer"
        >

        <div style="position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);">{{post.Title}}</div>
      </div>

      <div
        class="p-3"
        style="height: 50px"
      >

        <post-actions
          :post="post"
          :hideUnfavorited="true"
        ></post-actions>

      </div>

    </div>

    <!-- </router-link> -->

  </div>
</template>

<script>
import PostActions from "./PostActions.vue";

export default {
  components: { PostActions },
  props: ["post", "number"],
  data: function () {
    return {
      //post: post,

      checked: false
    };
  },
  mounted () {




  },
  methods: {
    go (postId) {
      this.$router.push({ name: 'Post', params: { postId: postId } })
    }
  },
};
</script>

<style>
.post-card {
  margin-top: 4px;
  margin-bottom: 12px;
  /* min-height: 198px;  */
  border: thin rgb(108, 108, 108) solid;
}

.overlay-actions {
  position: absolute;
  /* bottom:0; */
  /* right: 0; */
}

.title {
  color: #ffffff;
  font-size: 12px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>