import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/favorites",
    name: "Favorites",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "favorites" */ "../views/Favorites.vue"),
  },
  {
    path: "/educators",
    name: "Educators",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "educators" */ "../views/Educators.vue"),
  },
  {
    path: "/educator/:educatorId",
    name: "Educator",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "educator" */ "../views/Educator.vue"),
  },

  {
    path: "/post/:postId",
    name: "Post",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "post" */ "../views/Post.vue"),
  },
  {
    path: "/search",
    name: "Search",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/Search.vue"),
  },
  {
    path: "/rest",
    name: "Rest",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rest" */ "../views/Rest.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
  },

  {
    path: "/documents",
    name: "Documents",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "documents" */ "../views/Documents.vue"),
  },

  {
    path: "/mentor",
    name: "Mentor",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "mentor" */ "../views/Mentor.vue"),
  },

  {
    path: "/suggest",
    name: "Suggest",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "suggest" */ "../views/Suggest.vue"),
  },
  {
    path: "/section/:sectionName/:subSectionName",
    name: "SubSection",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "section" */ "../views/Section.vue"),
  },

  {
    path: "/section/:sectionName",
    name: "Section",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "section" */ "../views/Section.vue"),
  },

  {
    path: "/welcome",
    name: "Welcome",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "welcome" */ "../views/Welcome.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

import firebase from "firebase/app";
router.beforeEach((to, from, next) => {
  console.log("TO:", to);

  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      //user is logged in
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          console.log("USER", user);
          Store.commit({
            type: "login",
            data: user,
          });

          /////IMPORTANT - We are letting the user proceed to the next route BEFORE we check if active. Below, we test if they are active in Kartra. If they are not, we redirect to login. This speeds up page load
          next();

          //////////////check Kartra and make sure the logged in user is active
          var formdata = new FormData();
          formdata.append("email", user.email);
          var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
          };
          fetch(
            "https://app.cratehackers.com/api/1.1/wf/kartragetlead",
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              let res = JSON.parse(result);
              console.log(
                "RESULT",
                res.response.lead["_api_c2_lead_details.memberships"]
              );


              //check for Mentor Program
              let mentorProgram = res.response.lead[
                "_api_c2_lead_details.memberships"
              ].filter((membership) => {
                return (
                  membership._api_c2_name == "The DJ's Vault" &&
                  membership._api_c2_level_name == "Mentorship Calls" &&
                  membership._api_c2_active == 1
                );
              });

              if (mentorProgram.length) {
                //if memberships gt 0 then they have an active mentor account
                Store.commit({
                  type: "mentorProgram",
                  data: true,
                });
              }

              //check kartra memberships and make sure there is one that is active for The DJ's Vault
              let membershipsRaw = res.response.lead[
                "_api_c2_lead_details.memberships"
              ].filter((membership) => {
                return (
                  (membership._api_c2_name == "The DJ's Vault" &&
                    membership._api_c2_level_name == "Standard" &&
                    membership._api_c2_active == 1) ||
                  (membership._api_c2_name == "The DJ's Vault" &&
                    membership._api_c2_level_name == "Annual" &&
                    membership._api_c2_active == 1) ||
                  (membership._api_c2_name == "The DJ's Vault" &&
                    membership._api_c2_level_name == "Lite" &&
                    membership._api_c2_active == 1)
                );
              });
          

              if (membershipsRaw.length) {
                //if memberships gt 0 then they have an active account

                let memberships = membershipsRaw.filter((membership) => {
                  return (
                    membership._api_c2_level_name == "Annual" ||
                    membership._api_c2_level_name == "Standard" ||
                    membership._api_c2_level_name == "Lite"
                  );
                });

                let membershipsArray = [];

                memberships.forEach((membership) => {
                  membershipsArray.push(membership._api_c2_level_name);
                });

                if (membershipsArray.includes("Lite")) {
                         Store.commit({
                           type: "membershipLevel",
                           data: "lite",
                         });
                }

                if (membershipsArray.includes("Standard")) {
                     Store.commit({
                       type: "membershipLevel",
                       data: "standard",
                     });
                }

                if (membershipsArray.includes("Annual")) {
                    Store.commit({
                      type: "membershipLevel",
                      data: "annual",
                    });
                }

                // if (memberships.length > 0) {
                //   state.currentUser.annual = true;
                //   console.log("Store: ANNUAL", true);
                // } else {
                //   state.currentUser.annual = false;
                //   console.log("Store: ANNUAL", false);
                // }
                Store.commit({
                  type: "memberships",
                  data: memberships,
                });
                next();
              } else {
                next({ name: "Login" });
              }
            });
          ///////////////End Kartra check
        });

      //////LOG USER ACCESS

      var formdata = new FormData();
      formdata.append("email", user.email);
      formdata.append("page", to.path);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        "https://app.cratehackers.com/api/1.1/wf/djvaultlog",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    } else {
      if (to.name !== "Login") {
        next({ name: "Login" });
      } else {
        next();
      }
    }
  });
});

export default router;
