var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{attrs:{"id":"main-header"}},[_c('div',{},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light p-0"},[_c('a',{staticClass:"navbar-toggler c-toggler",attrs:{"href":"#","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('div',{staticClass:"navbar-toggler-icon",attrs:{"data-toggle":"collapse"},on:{"click":function($event){_vm.sidebarOpened = true}}},[_c('span',{staticClass:"navbar-menu-icon navbar-menu-icon--top"}),_c('span',{staticClass:"navbar-menu-icon navbar-menu-icon--middle"}),_c('span',{staticClass:"navbar-menu-icon navbar-menu-icon--bottom"})])]),_c('a',{staticClass:"navbar-brand",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.go('/')}}},[(
                  !this.$store.state.currentUser.membershipLevel == 'lite'
                )?_c('img',{staticClass:"img-fluid logo",attrs:{"src":"/images/logo.png","alt":"streamit"}}):_vm._e(),(this.$store.state.currentUser.membershipLevel == 'lite')?_c('img',{staticClass:"img-fluid logo",attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/litebg.png?alt=media&token=2d7abf13-0798-4959-ad3a-f7075f426cb3","alt":"streamit"}}):_vm._e()]),_c('div',{ref:"sidebarMenu",staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('div',{staticClass:"menu-main-menu-container"},[_c('span',{attrs:{"id":"close-btn"},on:{"click":_vm.closeSidebar}},[_vm._v("X")]),_c('ul',{staticClass:"navbar-nav ml-auto",attrs:{"id":"top-menu"}},[_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":'/section/marketing'}},[_vm._v("Marketing")])],1),_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":'/section/sales'}},[_vm._v("Sales")])],1),_c('li',{staticClass:"menu-item"},[(
                        !this.$store.state.currentUser.membershipLevel ==
                          'lite'
                      )?_c('router-link',{attrs:{"to":'/section/multi-op'}},[_vm._v("Multi-Op")]):_vm._e()],1),_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":'/section/gear'}},[_vm._v("Gear")])],1),_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":'/section/performance'}},[_vm._v("Performance")])],1),_c('li',{staticClass:"menu-item"},[(
                        !this.$store.state.currentUser.membershipLevel !==
                          'lite'
                      )?_c('router-link',{attrs:{"to":'/section/webinars'}},[_vm._v("Webinars")]):_vm._e()],1),(
                      this.$store.state.currentUser.membershipLevel !== 'lite'
                    )?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":'/section/officehours'}},[_vm._v("Office Hours")])],1):_vm._e(),(
                      this.$store.state.currentUser.membershipLevel !== 'lite'
                    )?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":'/documents'}},[_vm._v("Docs")])],1):_vm._e(),(
                      this.$store.state.currentUser.membershipLevel !== 'lite'
                    )?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":'/educators'}},[_vm._v("Educators")])],1):_vm._e(),(
                      this.$store.state.currentUser.membershipLevel !== 'lite'
                    )?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":'/rest'}},[_vm._v("The Rest")])],1):_vm._e(),(
                      this.$store.state.currentUser.membershipLevel ==
                        'annual'
                    )?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":'/section/annual'}},[_vm._v("Annual")])],1):_vm._e(),(
                      _vm.mayhem.includes(this.$store.state.currentUser.email)
                    )?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":'/section/mayhem'}},[_vm._v("Mayhem")])],1):_vm._e()])])]),_c('div',{staticClass:"navbar-right menu-right"},[_c('ul',{staticClass:"d-flex align-items-center list-inline m-0"},[_c('li',{staticClass:"nav-item nav-icon"},[_c('router-link',{attrs:{"to":'/favorites'}},[_c('i',{staticClass:"fa fa-heart  mr-3 text-danger"})]),(
                      this.$store.state.currentUser.membershipLevel !== 'lite'
                    )?_c('router-link',{attrs:{"to":'/search'}},[_c('i',{staticClass:"ri-search-line"})]):_vm._e()],1),_c('li',{staticClass:"menu-item d-none d-sm-block d-sm-none d-md-block",staticStyle:{"cursor":"pointer"}},[_c('a',{on:{"click":function($event){return _vm.logout()}}},[_vm._v("Logout")])]),_c('li',{staticClass:"nav-item nav-icon"},[_c('div',{staticClass:"iq-sub-dropdown iq-user-dropdown"},[_c('div',{staticClass:"iq-card shadow-none m-0"},[_c('div',{staticClass:"iq-card-body p-0 pl-3 pr-3"},[_c('a',{staticClass:"iq-sub-card setting-dropdown"},[_c('div',{staticClass:"media align-items-center"},[_vm._m(0),_c('div',{staticClass:"media-body ml-3"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$store.state.currentUser.displayName)+" ")])])])]),_c('a',{staticClass:"iq-sub-card setting-dropdown"},[_c('div',{staticClass:"media align-items-center"},[_vm._m(1),_c('div',{staticClass:"media-body ml-3"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$store.state.currentUser.uid)+" ")])])])]),_vm._m(2),_vm._m(3)])])])])])])]),_c('div',{staticClass:"nav-overlay"})])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right-icon"},[_c('i',{staticClass:"ri-file-user-line text-primary"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right-icon"},[_c('i',{staticClass:"ri-settings-4-line text-primary"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"iq-sub-card setting-dropdown"},[_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"right-icon"},[_c('i',{staticClass:"ri-settings-4-line text-primary"})]),_c('div',{staticClass:"media-body ml-3"},[_c('h6',{staticClass:"mb-0"},[_vm._v("Admin")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"iq-sub-card setting-dropdown"},[_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"right-icon"},[_c('i',{staticClass:"ri-logout-circle-line text-primary"})]),_c('div',{staticClass:"media-body ml-3"},[_c('h6',{staticClass:"mb-0"},[_vm._v("Logout")])])])])}]

export { render, staticRenderFns }