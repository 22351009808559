// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA7IuKFErT4SvdfWFyt-Isv7m-6mXBdDic",
    authDomain: "thedjsvault-307019.firebaseapp.com",
    projectId: "thedjsvault-307019",
    storageBucket: "thedjsvault-307019.appspot.com",
    messagingSenderId: "567486255500",
    appId: "1:567486255500:web:69ffacb2131fd55bf286a9",
    measurementId: "G-592D57YBVD"
  };
  import firebase from 'firebase/app'
  import 'firebase/firestore'
  import 'firebase/storage'
  
  // Get a Firestore instance
  firebase
    .initializeApp(firebaseConfig)

  export const db = firebase.firestore()
  export const storage = firebase.storage()
  
  // Export types that exists in Firestore
  // This is not always necessary, but it's used in other examples
  const { Timestamp, GeoPoint } = firebase.firestore
  export { Timestamp, GeoPoint }
  
  // if using Firebase JS SDK < 5.8.0
  db.settings({ timestampsInSnapshots: true })
  