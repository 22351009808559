<template>
  <div id="app" class="ml-4 mr-4">
    <Header v-if="this.$route.name !== 'Login'"></Header>

    <div class="main-content">
      <router-view :key="$route.fullPath" />
    </div>

    <Footer v-if="this.$route.name !== 'Login'"></Footer>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import "firebase/auth";
import Footer from "./components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {
    if (window.location.href.includes("thedjsvault-307019")) {
      window.location.replace("https://app.thedjsvault.com");
    }

    // this.login();
  },
  methods: {
    // login() {
    //   const _this = this;
    //   firebase.auth().onAuthStateChanged(function(user) {
    //     if (user) {
    //       ///get claims
    //       firebase
    //         .auth()
    //         .currentUser.getIdTokenResult()
    //         .then((idTokenResult) => {
    //           console.log("CLAIMS:", idTokenResult);
    //           user.membershipLevel = idTokenResult.claims.membershipLevel;
    //           user.active = idTokenResult.claims.active;
    //           // if (idTokenResult.claims.active == true) {
    //           _this.$store.commit({
    //             type: "login",
    //             user: user,
    //           });
    //           // } else {
    //           //   _this.$store.commit({
    //           //     type: "logout",
    //           //   });
    //           //      _this.$router.push("/login");
    //           // }
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //       console.log("******* Signed In", user);
    //     } else {
    //       _this.$router.push("/login").catch((err) => {});
    //       console.log("<<<<<< Signed Out");
    //     }
    //   });
    // },
  },
};
</script>

<style>
body {
  background-color: #151515;

}
footer {
  background-color: #151515;
}
.main-content {
  max-width: 1480px;
  margin: auto;
  /* width: 50%; */
  /* border: 3px solid green; */
  /* padding: 10px; */
}
.djv-page-title {
  font-family: "Montserrat", sans-serif;
  font-size: 60px;
  text-align: center;
  padding: 40px;
}
.mt {
  margin-top: 10px;
}
h6 {
  font-size: 12px;
}
.pointer {
  cursor: pointer;
}

.grow {
  transition: 1s ease;
}

.grow:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
  /* opacity: 0.4; */
}
.hover:hover{
  background-color: rgb(47, 47, 47);
}

/* header .navbar ul li.menu-item a {
  font-size: 11px;
} */
</style>
