<template>
  <div
    class="mt-4"
    style="max-width: 1480px; margin: auto;"
  >

    <!-- <div class="row">
      <div class="col-md-3">
        <a href="/suggest">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/DJV_Content_Idea_v1_960x960.png?alt=media&token=2ad792d1-b1ea-43ca-9758-28dcfefca866"
            class="img-fluid border border-dark rounded"
            alt=""
        /></a>
      </div>
      

      
      <div class="col-md-3">
        <a href="https://www.cratehackers.com" target="_blank">
          <img
            src="/images/ads/CrateHackers.png"
            class="img-fluid border border-dark rounded"
            alt=""
          />
        </a>
      </div>
      <div class="col-md-3">
        <a href="https://www.bunngear.com" target="_blank">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/bunn%20gear.png?alt=media&token=b8f6c0cb-5168-4555-9d4f-8d1857c07100"
            class="img-fluid border border-dark rounded"
            alt=""
          />
        </a>
      </div>
      <div class="col-md-3">
        <a href="https://calendly.com/joebunn/oneonone" target="_blank">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/joe.png?alt=media&token=78e25d94-7107-4cb8-883c-3f698806493d"
            class="img-fluid border border-dark rounded"
            alt=""
        /></a>
      </div>
    </div> -->

    <!-- <footer> -->
    <div class="row">
      <div class="col-md-12">
        <events></events>
      </div>

    </div>

    <!-- <div class="row mt-4">
        <div class="col-md-3">&nbsp;</div>
        <div class="col-md-6">
          <i class="fas fa-facebook-square fa-2x fa-fw mr-1"></i>
         <i class="fab fa-instagram-square"></i>
    
         
        </div>
        <div class="col-md-3">&nbsp;</div>
      </div> -->

    <br><br>
    <div class="row mt-4">
      <span class="col-md-12 text-center">Need help? Email us at <a
          class="text-danger"
          href="mailTo:help@thedjsvault.com"
        >help@thedjsvault.com</a></span>
    </div>
    <div class="row mt-4">
      <div class="col-md-12 text-center">Copyright 2024 - The DJs Vault</div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12 text-center"><a
          href="https://www.iubenda.com/privacy-policy/93415460/legal"
          target="_blank"
        >Privacy Policy</a> | <a
          href="https://www.iubenda.com/privacy-policy/93415460/cookie-policy"
          target="_blank"
        >Cookie Policy</a> | <a
          href="https://www.iubenda.com/terms-and-conditions/93415460"
          target="_blank"
        >Terms and Condtions</a></div>
    </div>
    <div class="row mt-4">
      <div
        class="col-md-12 text-justify"
        style="font-size: 11px"
      >
        The information provided on this website does not, and is not intended
        to, constitute legal, financial or accounting advice. Instead, all
        information, content and materials available on this site are for
        general information purposes only. The information provided and views
        expressed on this website are those of The DJ's Vault only – not those
        of an attorney, accountant, or other professional. Readers of this
        website should contact their attorney, accountant, or other
        professional to obtain advice with respect to a particular legal,
        financial, or accounting matter. No reader, user, or browser of this
        site should act or refrain from acting on the basis of legal,
        financial, or accounting information on this site without first
        seeking legal, financial, or accounting advice in a relevant
        jurisdiction. Only your individual attorney, accountant, or other
        professional can provide assurances that the information contained
        herein – and your interpretation of it – is applicable or appropriate
        to your particular situation. All liability with respect to actions
        taken or not taken based on the contents of this site are hereby
        expressly disclaimed. Entering this site means you agree to this
        statement.
      </div>

    </div>
    <!-- </footer> -->
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import Events from "./Events.vue";
export default {
  components: { Events },
};
</script>

<style>
.events {
  border: 1px solid #707070;
  height: 100%;
  border-radius: 4px;
  padding: 12px;
  background-image: url("/images/vaultbgDoc.jpg");
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
}
</style>
