<template>

  <div>
    <div
      v-if="post.Viewed"
      class="float-left"
    >{{post.Viewed.length}}

      <i
        v-if="post.Viewed.includes(this.$store.state.currentUser.uid)"
        class="fa fa-eye grow text-success"
      ></i>
      <i
        v-if="!post.Viewed.includes(this.$store.state.currentUser.uid)"
        class="fa fa-eye grow"
      ></i>

    </div>
    <div
      v-if="!post.Viewed"
      class="float-left"
    >0<i class="fa fa-eye grow"></i> </div>

    <div
      v-if="post.favorites"
      class="float-right"
    >{{post.favorites.length}}

      <i
        v-if="post.favorites && post.favorites.includes(this.$store.state.currentUser.uid)"
        class="fa fa-heart grow text-danger pointer"
        @click="removeFromFavorites()"
      ></i>
      <i
        v-if="post.favorites && !post.favorites.includes(this.$store.state.currentUser.uid)"
        class="fa fa-heart-o grow pointer"
        @click="addToFavorites()"
      ></i>

    </div>
    <div
      v-if="!post.favorites"
      class="float-right"
    >0 <i
        class="fa fa-heart-o grow pointer"
        @click="addToFavorites()"
      ></i> </div>
    <!-- <span v-if="favorited"
      ><i
        :id="this.$route.params.postId + Math.random()"
        class="fa fa-heart favorite grow pointer"
        @click="removeFromFavorites()"
      ></i
    ></span>
    <span v-if="!favorited && !hideUnfavorited"
      ><i
        :id="this.$route.params.postId + Math.random()"
        class="fa fa-heart un-favorite grow pointer"
        @click="addToFavorites()"
      ></i
    ></span>

    <i class="fa fa-check ml-2 check" v-if="viewed"></i> -->
  </div>
</template>

<script>
import { db } from "@/db.js";
const _ = require("lodash");
export default {
  props: ["post", "hideUnfavorited", "showViewedCheck"],
  data: function () {
    return {
      favorited: false,
      viewed: false, //did person view this

    };
  },
  watch: {
    post () {
      // this.checkStatus();
    },
  },
  mounted () {
    // this.checkStatus();
  },
  methods: {

    addToFavorites () {
      let currentFavorites;
      if (this.post.favorites) {
        currentFavorites = this.post.favorites;
      } else {
        currentFavorites = [];
      }

      currentFavorites.push(this.$store.state.currentUser.uid);
      console.log(currentFavorites);

      db.collection("posts")
        .doc(this.post.id)
        .update({
          favorites: currentFavorites,
        })
        .then(() => {
          this.favorited = true;
        });
    },

    removeFromFavorites () {
      let favorites;
      if (this.post.favorites) {
        favorites = this.post.favorites;
      } else {
        favorites = [];
      }
      //favorites = this.post.favorites
      let newFavIndex = favorites.indexOf(this.$store.state.currentUser.uid);
      favorites.splice(newFavIndex, 1);

      db.collection("posts")
        .doc(this.post.id)
        .update({
          favorites: favorites,
        })
        .then(() => {
          this.favorited = false;
        });
    },
  },
};
</script>

<style>
.favorite {
  color: red;
  text-shadow: 0px 0px 2px #ffffff, 0px 0px 2px #ffffff, 0px 0px 2px #ffffff,
    0px 0px 2px #ffffff;
}

.un-favorite {
  /* color: red; */
  text-shadow: 0px 0px 2px #ffffff, 0px 0px 2px #ffffff, 0px 0px 2px #ffffff,
    0px 0px 2px #ffffff;
}

.un-favorite:hover {
  color: red;
  text-shadow: 0px 0px 2px #ffffff, 0px 0px 2px #ffffff, 0px 0px 2px #ffffff,
    0px 0px 2px #ffffff;
}

.check {
  color: green;
  text-shadow: 0px 0px 2px #ffffff, 0px 0px 2px #ffffff, 0px 0px 2px #ffffff,
    0px 0px 2px #ffffff;
}
</style>