import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { firestorePlugin } from 'vuefire'




Vue.use(firestorePlugin)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueFormulate from '@braid/vue-formulate'

Vue.use(VueFormulate)


import VueAutosuggest from "vue-autosuggest";
Vue.use(VueAutosuggest);



Vue.filter('capitalize', (str) =>{
  return str.charAt(0).toUpperCase() + str.slice(1)
})

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

const jQuery = require('jquery');

Vue.config.productionTip = false

new Vue({
  router,
  store,
  jQuery,
  render: h => h(App)
}).$mount('#app')


