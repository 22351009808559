<template>
  <div class="">


   

    <section id="home">
      
      <div id="mobile-view" class="d-block d-sm-none .d-none .d-sm-block .d-md-none" >
          <h2 class="col-xs-6 text-uppercase text-center text-bold m-2" >
                  welcome to <br>the dj's vault <span v-if="this.$store.state.currentUser.membershipLevel=='lite'" style="color: #eb9e34"><i>lite</i></span>
                </h2>
                  <p class="m-2 text-center"
                data-animation-in="fadeInUp"
                data-delay-in="1.2"
              >
                You are about to enter a community of thousands of mobile
                DJs around the world. <br>Use the information inside and the
                connections you make to take your<br> company to the next level.
                <br>As always,
                <u><a href="mailto:help@thedjsvault.com">email us</a></u> if you
                <br>have any questions.
              </p>
      </div>
      
      
      
          <!-- Slider Start MD and HIGHER -->
      <div id="home-slider"   class="d-none d-sm-block d-sm-none d-md-block" >

        <div class="row slick-bg s-bg-1 ">
          <div class="col-md-8 ">

            <div class="ml-4">

              <div
                class="row"
                style="margin-top:8px;"
              >
                <div class="col-xs-6 welcome-text text-uppercase text-bold m-2">
                  welcome to <br>the dj's vault <span v-if="this.$store.state.currentUser.membershipLevel=='lite'" style="color: #eb9e34"><i>lite</i></span>
                </div>
                <div class="col-xs-6">
                  <div class="channel-lopo  text-uppercase text-bold">

       
                  </div>

                </div>
              </div>

              <p
                data-animation-in="fadeInUp"
                data-delay-in="1.2"
              >
                You are about to enter a community of thousands of mobile
                DJs around the world. <br>Use the information inside and the
                connections you make to take your company to the next level.
                <br>As always,
                <u><a href="mailto:help@thedjsvault.com">email us</a></u> if you
                have any questions.
              </p>
              <div
                class="d-flex align-items-center r-mb-23"
                data-animation-in="fadeInUp"
                data-delay-in="1.2"
              >
             
               
              </div>
            </div>
          </div>
          <div class="col-md-4">

          </div>
        </div>

      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        style="display: none"
      >
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 44 44"
          width="44px"
          height="44px"
          id="circle"
          fill="none"
          stroke="currentColor"
        >
          <circle
            r="20"
            cy="22"
            cx="22"
            id="test"
          ></circle>
        </symbol>
      </svg>
    </section>
    <!-- Slider End -->

    <section v-if="showWelcomeVideo">
      <video
        width="320"
        height="240"
        controls
      >
        <source
          :src="welcomeVideo"
          type="video/mp4"
        />
        <source
          :src="welcomeVideo"
          type="video/ogg"
        />
        Your browser does not support the video tag.
      </video>
    </section>
    <div class="row mt">
      <div class="col-md-12">
        <div class="main-content ">
          <h4 class="mt-4 main-title">Latest Videos</h4>
          <div class="row">

            <div
              class="col-md-2"
              v-for="post in posts"
              :key="post.id"
            >
        
              <post-card :post="post"></post-card>

            </div>
          </div>

          <!-- ADS -->

          <section>
            <div class="mt-2">
     

              <!-- <h4 class="mt-4 main-title">Meet Your Educators</h4>
              <div class="row">

                <div
                  class="col-md-2"
                  v-for="educator in educators"
                  :key="educator.id"
                >


                <img :src="educator.image" class="rounded-circle" style="width:80px; height:80px" />

        

                </div>
              </div> -->

              <!-- <div class="row mt-3">
                <div class="col-md-3"> <a
                    href="https://www.thedjsvault.com/raleigh"
                    target="_blank"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/raleigh.png?alt=media&token=03e1515b-58e2-4755-998d-9a0b9d630e58"
                      class="img-fluid border border-dark rounded mt-1"
                      alt=""
                    /></a></div>

                <div class="col-md-2 overflow-hidden">

                  <a
                    href="https://www.vibodj.com?ref=vault"
                    target="_blank"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/vibo.jpeg?alt=media&token=18b4420d-2d5d-48db-8a6b-470cef39d865"
                      class="img-fluid border border-dark rounded mt-1"
                      alt=""
                    /></a>

                </div>

                <div class="col-md-2">
                  <a
                    href="http://www.mtmcourse.com/"
                    target="_blank"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/MTM_OutNow_700x700_v1.png?alt=media&token=8b9086fb-257a-48a1-8699-e8b8bc174783"
                      class="img-fluid border border-dark rounded mt-1"
                      alt=""
                    /></a>
                </div>
                <div class="col-md-2">
                  <a href="/suggest">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/DJV_Content_Idea_v1_960x960.png?alt=media&token=2ad792d1-b1ea-43ca-9758-28dcfefca866"
                      class="img-fluid border border-dark rounded mt-1"
                      alt=""
                    /></a>
                </div>

                <div class="col-md-2">
                  <a
                    href="https://www.cratehackers.com"
                    target="_blank"
                  >
                    <img
                      src="/images/ads/CrateHackers.png"
                      class="img-fluid border border-dark rounded mt-1"
                      alt=""
                    />
                  </a>
                </div>

                <div class="col-md-2">
                  <a
                    href="http://bothlightingusa.com/joebunn"
                    target="_blank"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/both.png?alt=media&token=f389cc47-7074-4d3b-ad38-9ec6a9ecc35d"
                      class="img-fluid border border-dark rounded mt-1"
                      alt=""
                    />
                  </a>
                </div>

                <div class="col-md-3 overflow-hidden">

                  <a
                    href="https://calendly.com/joebunn/oneonone"
                    target="_blank"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/joe.png?alt=media&token=78e25d94-7107-4cb8-883c-3f698806493d"
                      class="img-fluid border border-dark rounded mt-1"
                      alt=""
                    /></a>

                </div>

                <div class="col-md-2 overflow-hidden">

                  <a
                    href="https://www.smplsystem.com"
                    target="_blank"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/SMPL.jpg?alt=media&token=269ed6a5-ca89-4caf-ab08-407aeced6f31"
                      class="img-fluid border border-dark rounded mt-1"
                      alt=""
                    /></a>

                </div>
              </div> -->
            </div>
          </section>
          <br>
          <span   v-if="this.$store.state.currentUser.membershipLevel !== 'lite'">
          <div
        
            v-for="path in paths"
            :key="path.id"
          >
            <h4 class="mt-4 main-title">{{path.title}}</h4>
            <div class="row">

              <div
                class="col-md-2"
                v-for="(p,ind) in path.posts"
                :key="ind"
              >

                <post-card :post="p"></post-card>

              </div>
            </div>

          </div>

                </span>   <div class="row mt-2">

                <div
                  class="col-md-2 overflow-hidden"
                  v-for="ad in ads"
                  :key="ad.id"
                >

                  <a
                    :href="ad.link"
                    target="_blank"
                  >
                    <img
                      :src="ad.image"
                      class="img-fluid border border-dark rounded mt-1"
                      alt=""
                    /></a>

                </div>
              </div>

          <!-- <h4 class="mt-4">Need help closing sales? Start here</h4>
          <div class="row">

            <div
              class="col-md-3"
              v-for="post in postsSales"
              :key="post.id"
            >

              <post-card :post="post"></post-card>

            </div>
          </div>

          <h4 class="mt-4">Need Marketing Help? Start here</h4>
          <div class="row">

            <div
              class="col-md-3"
              v-for="post in postsSales"
              :key="post.id"
            >

              <post-card :post="post"></post-card>

            </div>
          </div>

          <h4 class="mt-4">Trying to Scale? Start here</h4>
          <div class="row">

            <div
              class="col-md-3"
              v-for="post in postsSales"
              :key="post.id"
            >

              <post-card :post="post"></post-card>

            </div> 
          </div>-->
          <!-- <slider
            id="marketing"
            :title="'Gear Heads Start Here'"
            :posts="postsMarketing"
            class="mt-4"
            :showNumber="true"
          ></slider> -->

          <!-- <slider
            id="sales"
            :title="'Need help closing sales? Start here'"
            :posts="postsSales"
              class="mt-4"
                :showNumber="true"
          ></slider> -->

          <!-- <slider
            id="multiop"
            :title="'Need Marketing Help? Start Here'"
            :posts="postsMultiOp"
              class="mt-4"
                :showNumber="true"
          ></slider> -->

          <!-- <slider
            id="multiop"
            :title="'Trying to scale? Start here'"
            :posts="postsMarketing"
              :showNumber="true"
              class="mt-4"
          ></slider> -->

        </div>
      </div>
      <!-- <div class="col-md-2">
        <div
          class="rounded"
          style="background-color: #333333; padding: 10px; margin-top: 50px"
        >
          <a
            href="https://www.thedjsvault.com/raleigh"
            target="_blank"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/raleigh.png?alt=media&token=03e1515b-58e2-4755-998d-9a0b9d630e58"
              class="img-fluid border border-dark rounded mt-1"
              alt=""
            /></a> <a
            href="http://www.mtmcourse.com/"
            target="_blank"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/MTM_OutNow_700x700_v1.png?alt=media&token=8b9086fb-257a-48a1-8699-e8b8bc174783"
              class="img-fluid border border-dark rounded mt-1"
              alt=""
            /></a>
          <a href="/suggest">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/DJV_Content_Idea_v1_960x960.png?alt=media&token=2ad792d1-b1ea-43ca-9758-28dcfefca866"
              class="img-fluid border border-dark rounded mt-1"
              alt=""
            /></a>
          <a
            href="https://www.cratehackers.com"
            target="_blank"
          >
            <img
              src="/images/ads/CrateHackers.png"
              class="img-fluid border border-dark rounded mt-3"
              alt=""
            />
          </a>

          <a
            href="https://calendly.com/joebunn/oneonone"
            target="_blank"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/joe.png?alt=media&token=78e25d94-7107-4cb8-883c-3f698806493d"
              class="img-fluid border border-dark rounded mt-3"
              alt=""
            /></a>
        </div>
      </div> -->
    </div>

  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

// import slider from "../components/Slider.vue";


const axios = require("axios");
import { db } from "@/db.js";
import PostCard from '../components/PostCard.vue';

export default {
  name: "Home",
  components: { PostCard },
  data: function () {
    return {
      posts: [],
      postsFavorites: [],
      postsSuggested: [],
      educators: [],
      paths: [],
      postsSales: [],
      postsMultiOp: [],
      awsPosts: [],
      showWelcomeVideo: false,
      ads: [],
      welcomeVideo:
        "https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/welcome.mov?alt=media&token=80019ac7-dfc0-45c7-933a-b1c353de3f06",
      baseUrl: this.$store.state.baseUrlWF,
      uid: "",
    };
  },
  mounted () {

    // this.animate();
    this.getPosts();


  },

  methods: {
    getPosts () {


      
      this.$bind(
        "posts",
        db.collection("posts")
          .orderBy("NewOrder", "desc")
    // .limit(40)
      ).then(() => {

        //get rid of documents
        let posts = this.posts;

        const mayhem = posts.filter((post) => post.Section !== "mayhem");
        const results = mayhem.filter((post) => post.Section !== "annual");
        const resultsNoLogo = results.filter((post) => post.Subsection !== "logo reviews");
        const resultsWithoutDocs = resultsNoLogo.filter(
          (post) => post.Video !== ""
        );
        let resultsFinal = []
        if(this.$store.state.currentUser.membershipLevel == 'lite')
        resultsFinal = resultsWithoutDocs.filter((post) => post.lite == true);
      else{
        resultsFinal = resultsWithoutDocs
      }

        this.posts = resultsFinal.slice(0, 12);
      });


      this.$bind(
        "paths",
        db
          .collection("paths")
          .where("page", "==", "home")
          .orderBy("order", "asc")
          .limit(10)
      ).then(function () { });

      this.$bind(
        "educators",
        db
          .collection("educators")
          .where("active", "==", true)
        // .orderBy("order", "asc")
        // .limit(10)
      ).then(function () { });

      this.$bind(
        "postsSales",
        db
          .collection("posts")
          .where("Section", "==", "sales")
          .orderBy("PublishedDate", "desc")
          .limit(4)
      ).then(function () { });

      this.$bind(
        "postsMultiOp",
        db
          .collection("posts")
          .where("Section", "==", "multi-op")
          .orderBy("PublishedDate", "desc")
          .limit(4)
      ).then(function () { });

      this.$bind(
        "ads",
        db
          .collection("ads")
          .where("active", "==", true)
          // .orderBy("order", "desc")
          .limit(20)
      ).then(() => {

        let shuffled = this.ads.sort(() => Math.random() - 0.5)

        this.ads = shuffled.slice(0, 6);
        console.log("SHUFFLED", this.ads)
      });
    },



    getFavorites () {
      const _this = this;
      this.$bind(
        "postsFavorites",
        db
          .collection("posts")
          .where(
            "favorites",
            "array-contains",
            this.$store.state.currentUser.uid
          )
      ).then(function () {

        console.log("postsFavorites", _this.postsFavorites);
      });
    },





  },
};
</script>

<style scoped>
/* a {
  color: red;
  font-weight: bolder;
} */
#iq-favorites {
  max-width: 1180px;
  margin: auto;
}
.channel-logo {
  width: 100%;
}
/* #home-slider .slick-bg {
  height: 440px;
} */
</style>
